import { Flex } from "@storyofams/react-ui";
import { NextSeo } from "next-seo";
import { defineMessages, useIntl } from "react-intl";

import { LegacyButton, Heading } from "~components";
import { globalStaticProps, withLayout } from "~lib";

const getError = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const messages = defineMessages({
  unauthorized: "It looks like you are not supposed to be here.",
  notFound: "Sorry, we couldn't find the page you were looking for.",
  serverError: "Our server had some trouble processing that request.",
  unknown: "Something unexpected happened.",
  back: "Back to home",
});

const getContent = ({ statusCode, intl }) => {
  switch (statusCode) {
    case 401:
      return intl.formatMessage(messages.unauthorized);
    case 404:
      return intl.formatMessage(messages.notFound);
    case 500:
      return intl.formatMessage(messages.serverError);
    default:
      return intl.formatMessage(messages.unknown);
  }
};

const Error = ({ statusCode }) => {
  const intl = useIntl();
  const content = getContent({ statusCode, intl });

  return (
    <>
      <NextSeo title={statusCode} description={content} />
      <Flex
        flex="1"
        pt={[8, 20]}
        pb={[8, 20]}
        justifyContent="center"
        flexDirection="column"
      >
        <Heading
          textAlign="center"
          mx="auto"
          variant="h3"
          mb={[4, 8]}
          color="grey900"
          maxWidth={["340px", "500px"]}
        >
          {content}
        </Heading>
        <LegacyButton mx="auto" variant="primary" to="/">
          {intl.formatMessage(messages.back)}
        </LegacyButton>
      </Flex>
    </>
  );
};

export default withLayout(Error, {
  paddingBottom: 10,
});

export const getStaticProps = globalStaticProps(async ({ res, err }: any) => ({
  props: {
    ...getError({ res, err }),
  },
}));
