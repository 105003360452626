import { GetStaticProps } from "next";

import { globalStaticProps } from "~lib";

import Error from "./_error";

const NotFoundPage = (props) => <Error statusCode={404} {...props} />;

export default NotFoundPage;

export const getStaticProps: GetStaticProps = globalStaticProps(async () => ({
  props: {},
}));
